import React from "react";

const StyleGuide = () => {
	return (
		<div className="c-black p1" style={{ margin: "160px 50px" }}>
			<label className="label">Display</label>
			<h1 className="display">
				<em>An Agency</em> <span className="upper">UNLIKE ANY OTHER</span>
			</h1>
			<label className="label">Headline</label>
			<h2 className="headline">
				<em>An Agency</em> <span className="upper">UNLIKE ANY OTHER</span>
			</h2>
			<label className="label">Small Headline</label>
			<h3 className="small-headline">
				<em>An Agency</em> <span className="upper">UNLIKE ANY OTHER</span>
			</h3>
			<label className="label">Title</label>
			<h3 className="title">
				Katie Conovitz, CEO <em>&amp; Founder</em>
			</h3>
			<label className="label">Small Title</label>
			<h3 className="small-title">
				Katie Conovitz, CEO <em>&amp; Founder</em>
			</h3>
			<label className="label">Body</label>
			<p className="body">
				Body: twelveNYC is an agency unlike any other. Founded in 2005, we were
				the first – and still the only – creative resource to intersect tangible
				product and brand storytelling. Now operating from three continents, we
				are recognized as one of the top creative product development and
				sourcing agencies in the world. We have become the trusted partner of
				choice for leading brands through our irreplicable, end-to-end creative
				process and ability to maximize the 24-hour international work cycle.
			</p>
			<label className="label">Small Body</label>
			<p className="small-body">
				Small Body: Our strength is in our people. We are proudly 100%
				women-owned and 91% women-powered, and through a highly creative and
				collaborative approach, your strategic partner through each step of the
				product development process from first idea to final delivery, When you
				work with twelveNYC, you work with tenacious, crazy-smart people with
				diverse experiences and skill sets that know how to divine and deliver
				what’s new and next.
			</p>
			<label className="label">Caption</label>
			<p className="caption">
				Caption: Packaging that makes the clean beauty leader stand out on
				shelves, while using biodegradable materials.
			</p>
			<label className="label">Label</label>
			<p className="label">Who We Are</p>
			<label className="label">LCD</label>
			<p className="lcd">B Corp Certified</p>
			<label className="label">LCD Small</label>
			<p className="lcd-small">Good evening</p>
		</div>
	);
};

export default StyleGuide;
